import React, { useState } from 'react';
import { default as NextHead } from 'next/head';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { FormPageWrapper } from '../components/FormComponents/FormPageWrapper';
import { LoginForm } from '../components/FormComponents/LoginForm';
import { RegisterForm } from '../components/FormComponents/RegisterForm';
import { Layout } from '../components/Layout';

const ALLOWED_FORM_MODES = ['login', 'register'];

const Login = () => {
    const { t } = useTranslation();
    const {
        query: { redirect, formMode: queryFormMode = '' }
    } = useRouter();

    const formModeQueryAsString = `${queryFormMode}`;
    const [formMode, setFormMode] = useState<string>(
        ALLOWED_FORM_MODES.includes(formModeQueryAsString) ? formModeQueryAsString : 'login'
    );

    const showLoginForm = () => setFormMode('login');
    const showRegisterForm = () => setFormMode('register');

    const rawRedirectUrl = redirect || !Array.isArray(redirect) ? redirect : '';
    const redirectUrl = decodeURIComponent((rawRedirectUrl as string) ?? '') === '/' ? '' : rawRedirectUrl;

    const formComponent =
        formMode === 'login' ? (
            <LoginForm redirect={redirectUrl as string} registerToggle={showRegisterForm} />
        ) : (
            <RegisterForm loginToggle={showLoginForm} />
        );

    return (
        <Layout title={`Bladebinge - ${t('common:auth.login')}`}>
            <>
                <NextHead>
                    <link rel="canonical" href={`${process.env.ENV_UI_SERVER_URL}/login`} />
                </NextHead>
                <FormPageWrapper>{formComponent}</FormPageWrapper>
            </>
        </Layout>
    );
};

export const getServerSideProps = async ({ locale }: { locale: string }) => ({
    props: await serverSideTranslations(locale, ['cms', 'common', 'legal'])
});

export default Login;
