import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NextRouter, useRouter } from 'next/router';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { EMAIL_REGEX, validateEmail } from '@bladebinge/web-service-common/src/validations/validate-email';
import { validatePassword } from '@bladebinge/web-service-common/src/validations/validate-password';
import { validateUsername } from '@bladebinge/web-service-common/src/validations/validate-username';
import { useTranslation } from 'next-i18next';
import Typography from '@mui/material/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setMeInLocalStorage } from '@bladebinge/web-service-common/src/utils/local-storage/local-storage-util';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { CurrentUserPersonalData, UserGraph } from '@bladebinge/types';
import { useMe } from '../../context/me/me-context';
import { useUserInterfaceNotificationsContext } from '../../context/user-interface-notifications/user-interface-notifications-context';
import { nextLocalStorage } from '../../utils/next-local-storage-helper';
import { useHasMounted } from '../../hooks/use-has-mounted';
import {
    StyledFormMessagingWrapper,
    StyledFullWidthForm,
    StyledHookFormErrorMessage,
    StyledTextLink
} from '../styled-shared';
import { LoadingIndicator } from '../atoms/LoadingIndicator';
import { loginUser } from '../../server/api-proxy/ui-mutation-fns/login-user';
import { FormHeader } from './FormHeader';
import { PasswordInput } from './PasswordInput';

interface LoginFormState {
    userid: string;
    password: string;
    rememberMe: boolean;
}

const redirectToLoggedInHomePage = ({
    userId,
    redirect,
    router
}: {
    userId?: string | null;
    redirect?: string;
    router: NextRouter;
}) => {
    if (!userId) {
        return;
    }

    const redirectedRouteForUpdatedUser = redirect
        ? redirect.replace(/users\/([a-zA-Z0-9-]+)\//, `users/${userId}/`)
        : `/`;
    router.push(redirectedRouteForUpdatedUser, undefined, { shallow: true });
};

export const LoginForm = ({
    redirect,
    registerToggle,
    showFormHeader = true,
    successCallback
}: {
    readonly redirect?: string;
    readonly registerToggle: () => void;
    readonly showFormHeader?: boolean;
    readonly successCallback?: () => void;
}) => {
    const { t } = useTranslation();
    const hasMounted = useHasMounted();
    const queryClient = useQueryClient();
    const router = useRouter();
    const { id: loggedInUserId, setMe } = useMe();
    const { invalidateNotificationsCounts } = useUserInterfaceNotificationsContext();

    const loginUserMutation = useMutation({
        mutationFn: loginUser,
        onSuccess(response) {
            const { me = null, user = null } = response as {
                me: CurrentUserPersonalData;
                user: UserGraph;
            };
            setMeInLocalStorage(me);
            setMe(me);
            invalidateNotificationsCounts();
            queryClient.setQueryData(uiCacheKeyBuilderMap.loggedInUser({ loggedInUserId }), user);

            if (successCallback) {
                successCallback();
            }

            if (me?.id) {
                redirectToLoggedInHomePage({
                    userId: me.id,
                    redirect,
                    router
                });
            }
        }
    });

    const { error: loginError, isPending: processingLogin, reset: resetLoginMutation } = loginUserMutation;

    const [localStorageState, setLocalStorageState] = useState<{
        email: string;
        userid: string;
        rememberMe: boolean;
    }>({
        email: '',
        rememberMe: false,
        userid: ''
    });

    const {
        formState: { errors },
        getValues,
        handleSubmit,
        register,
        setValue
    } = useForm<LoginFormState>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: true
    });

    const handleRememberCheck = (_e: React.ChangeEvent<unknown>, checked: boolean) => {
        nextLocalStorage.set('rememberMe', `${checked}`);

        const loginValue = getValues('userid');
        const userData = EMAIL_REGEX.test(loginValue) ? { email: loginValue } : { userid: loginValue };

        setLocalStorageState((prevState) => ({
            ...prevState,
            ...(loginValue && checked ? userData : {}),
            rememberMe: checked
        }));
    };

    const validateUserIdentifier = (id = '') => (id.includes('@') ? validateEmail(id) : validateUsername(id));

    const handleLogin = (formData: { userid: string; password: string; rememberMe: boolean }) => {
        const { rememberMe, ...loginData } = formData;

        if (rememberMe) {
            nextLocalStorage.set('rememberMe', rememberMe.toString());
            EMAIL_REGEX.test(loginData.userid)
                ? nextLocalStorage.set('email', loginData.userid)
                : nextLocalStorage.set('userid', loginData.userid);
        } else {
            nextLocalStorage.remove('email');
            nextLocalStorage.remove('rememberMe');
            nextLocalStorage.remove('userid');
        }

        loginUserMutation.mutate({ loginFormData: loginData });
    };

    // on mount update from local storage
    useEffect(() => {
        const localRememberMe = nextLocalStorage.getAsBoolean('rememberMe');
        const rememberedEmail = localRememberMe ? (nextLocalStorage.get('email') ?? '') : '';
        const rememberedUserId = localRememberMe ? (nextLocalStorage.get('userid') ?? '') : '';
        setLocalStorageState({
            email: rememberedEmail,
            rememberMe: localRememberMe,
            userid: rememberedUserId
        });
    }, []);

    // set form fields if localStorage state gets updated
    useEffect(() => {
        const localStoreLogin = localStorageState.userid || localStorageState.email || '';
        setValue('userid', localStoreLogin);
        setValue('rememberMe', localStorageState.rememberMe);
    }, [localStorageState, setValue]);

    // hook form material UI register calls
    const { ref: useridRef, ...useridRest } = register('userid', { validate: validateUserIdentifier });
    const { ref: passwordRef, ...passwordRest } = register('password', {
        required: true,
        validate() {
            return validatePassword(getValues('password'));
        }
    });
    const { ref: rememberMeRef, ...rememberMeRest } = register('rememberMe');

    if (!hasMounted) {
        return null;
    }

    // redirect if loggedInUserId is set
    redirectToLoggedInHomePage({
        userId: loggedInUserId,
        redirect,
        router
    });

    if (loggedInUserId || processingLogin || processingLogin) {
        return <LoadingIndicator />;
    }

    return (
        <>
            {showFormHeader && <FormHeader icon="lock" title={t('common:auth.sign_in')} />}
            <StyledFullWidthForm onSubmit={handleSubmit(handleLogin)}>
                {loginError && (
                    <StyledFormMessagingWrapper>
                        <Alert onClose={resetLoginMutation} severity="error">
                            {loginError.message}
                        </Alert>
                    </StyledFormMessagingWrapper>
                )}
                <Grid container>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={errors.userid ? 'input-error' : ''}
                        fullWidth
                        id="userid"
                        label={t('common:auth.email_or_username')}
                        placeholder={t('common:auth.email_or_username')}
                        margin="normal"
                        required
                        variant="outlined"
                        inputRef={useridRef}
                        {...useridRest}
                    />
                    <StyledHookFormErrorMessage
                        as="div"
                        errors={errors}
                        message={t('common:auth.errors.invalid_userid_or_email')}
                        name="userid"
                    />
                </Grid>
                <Grid container>
                    <PasswordInput
                        autoComplete="current-password"
                        hasError={Boolean(errors.password)}
                        id="password"
                        inputRef={passwordRef}
                        label={t('common:auth.password')}
                        placeholder={t('common:auth.password')}
                        required
                        passwordRest={passwordRest}
                    />
                    <StyledHookFormErrorMessage
                        as="div"
                        data-testid="login_password_error"
                        errors={errors}
                        message={t('common:auth.errors.invalid_password')}
                        name="password"
                    />
                </Grid>
                <FormControlLabel
                    checked={localStorageState.rememberMe}
                    control={<Checkbox />}
                    inputRef={rememberMeRef}
                    label={t('common:auth.remember_me')}
                    {...rememberMeRest}
                    onChange={handleRememberCheck}
                />
                <Button
                    color="primary"
                    data-testid="submit_login_btn"
                    disabled={processingLogin}
                    fullWidth
                    size="large"
                    sx={{
                        mt: 2,
                        mx: 0,
                        mb: 3
                    }}
                    type="submit"
                    variant="contained"
                >
                    {t('common:auth.sign_in')}
                </Button>
                <Grid
                    container
                    justifyContent="space-between"
                    sx={{
                        flexDirection: {
                            xs: 'row-reverse',
                            sm: 'inherit'
                        },
                        textAlign: 'left'
                    }}
                >
                    <Grid
                        sx={{
                            textAlign: {
                                xs: 'right',
                                sm: 'inherit'
                            },
                            mb: {
                                xs: 0.5
                            }
                        }}
                        size={{
                            xs: 12,
                            sm: 4
                        }}
                    >
                        <Typography component="span" variant="body2" sx={{ color: 'inherit' }}>
                            <StyledTextLink href="/forgot_password">{t('common:auth.forgot_password')}</StyledTextLink>
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            textAlign: {
                                xs: 'right',
                                sm: 'inherit0'
                            },
                            mb: {
                                xs: 0.5
                            }
                        }}
                        size={{
                            xs: 12,
                            sm: 4
                        }}
                    >
                        <Button
                            variant="text"
                            onClick={(e: React.BaseSyntheticEvent) => {
                                e.preventDefault();
                                registerToggle();
                            }}
                            color="primary"
                            sx={{ textDecoration: 'underline' }}
                        >
                            {t('common:auth.create_account')}
                        </Button>
                    </Grid>
                </Grid>
            </StyledFullWidthForm>
        </>
    );
};
