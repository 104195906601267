import { CurrentUserPersonalData, LoginFormState } from '@bladebinge/types';
import { UserGraph } from '@bladebinge/types/src/api/models/user';
import { normalizedFetch } from '../utils/normalized-fetch';
import { PROXY_ROUTES } from '../ui-proxy-routes';

export const loginUser = async (
    {
        loginFormData
    }: {
        loginFormData: Partial<LoginFormState>;
    },
    signal?: AbortSignal
): Promise<
    | {
          me: CurrentUserPersonalData;
          user: UserGraph;
      }
    | { error: { message: string } }
> => {
    const response = await normalizedFetch({
        endpoint: PROXY_ROUTES.login.build(),
        options: {
            method: 'POST',
            body: JSON.stringify({
                usernameOrEmail: loginFormData?.userid,
                password: loginFormData?.password
            }),
            headers: {
                'Content-Type': 'application/json'
            },
            ...(signal ? { signal } : {})
        }
    });

    const { error: { message: apiErrorMessage } = {} } = response as {
        error?: {
            message: string;
        };
    };

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};
