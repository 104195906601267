import React, { RefCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const PasswordInput = ({
    autoComplete,
    hasError,
    id,
    inputRef,
    label,
    placeholder,
    required,
    passwordRest
}: {
    readonly autoComplete?: string;
    readonly hasError: boolean;
    readonly id: string;
    // eslint-disable-next-line
    inputRef: RefCallback<any>;
    readonly label: string;
    readonly placeholder: string;
    readonly required: boolean;
    // eslint-disable-next-line
    passwordRest: any;
}) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);

    return (
        <TextField
            InputLabelProps={{ shrink: true }}
            autoComplete={autoComplete}
            className={hasError ? 'input-error' : ''}
            variant="outlined"
            margin="normal"
            fullWidth
            label={label}
            placeholder={placeholder}
            required={required}
            type={showPassword ? 'text' : 'password'}
            id={id}
            inputRef={inputRef}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={toggleShowPassword} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            {...passwordRest}
        />
    );
};
